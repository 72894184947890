import React from "react"
import { Link } from "gatsby"
import Footer from "../components/footer"
import Sprites from "../components/sprites"
import SEO from "../components/seo"


import logoGold from "../img/gh-rough.jpg"

export default function terms() {
    return (
      <div className="thank-you background--mid">
          <SEO title="Gypsy Hub | Contract Brewing &amp; Distilling | Melbourne, Australia" description="Contract Distilling &amp; Gypsy Brewing providers in Melbourne offering solutions to Australian commercial craft beverage producers." />
      <Sprites></Sprites>
            <div className="wrapper terms--content thank-you--content">
                <div className="row">
                    <div className="col-12">
                      <h1>Terms of Service</h1>

                    <Link to="/" className="dark-link"><span className="back-arrow">→</span>Return Home</Link>

                    <p>GYPSY HUB PTY LTD (ACN: 625 049 309) as trustee for the Gypsy Hub Trust (ABN: 87 694 455 223) of 96 Chifley Drive,
                      Preston VIC 3172 ("the Supplier")</p>

                      <p>and</p>
<p>The CUSTOMER as defined in the Schedule or Quote ("Customer")</p>
<p><strong>background</strong></p>
<p>A The Supplier has skills and experience in providing beverage manufacturing services, including recipe formulation,
  production, packaging and storage services ("the Services").<br />B The Customer wishes to engage an independent
  contractor to provide the Services to the Customer.<br />C The Supplier is willing provide the Services and the
  Customer is willing to appoint the Supplier to provide the Services in accordance with the terms of this Agreement.
</p>
<h2>OPERATIVE PROVISIONS</h2>
<h2>1. Definitions and interpretation</h2>
<h3>1.1 Definitions</h3>
<p>In this Agreement unless the context indicates otherwise, the following words have the following meanings.</p>
<p>Claims means all demands, claims, proceedings, penalties, fines and liability (whether criminal or civil, in
  contract, tort or otherwise).</p>
<p>Confidential Information includes any information marked as confidential and any information received or developed
  during the term of this Agreement, which is not publicly available and relates to processes, equipment and techniques
  used by either the Supplier or the Customer in the course of their business. This includes all information, data,
  drawings, specifications, documentation, source or object code, designs, construction, workings, functions, features
  and performance notes, techniques, concepts not reduced to material form, agreements with third parties, schematics
  and proposals and intentions, technical data and marketing information such as customer lists, financial information
  and business plans.</p>
<p>Customer means the person identified in the Schedule or quote, and includes any officers, employees, contractors,
  representatives or consultants of that person or entity.</p>
<p>Facilities means the Supplier's machines, apparatus, tools, or equipment of any kind located in the Supplier's
  Premises which may be required to be used to perform the Services.</p>
<p>Fees means the fees, and/or any rates set out in the Schedule or quote, as updated from time to time in accordance
  with clause 4.3.</p>
<p>GST Law means the same as in the A New Tax System (Goods and Services Tax) Act 1999 (Cth).</p>
<p>Intellectual Property includes trade marks, patents, copyrights, processes know-how, registered designs or other like
  rights or any right to apply for registration of any of the former.</p>
<p>Key Control Points means the items set out in Schedule or Quote C.</p>
<p>Laws means all common law, principles of equity, statute, legislation or subordinate legislation of the Commonwealth
  of Australia, the State of Victoria or local and other government regulations enforced in the State of Victoria,
  irrespective of where enacted.</p>
<p>Losses means all losses including financial losses, damages, legal costs and other expenses of any nature whatsoever.
</p>
<p>Schedule means a Schedule or quote attached to this Agreement.</p>
<p>Parties means the Supplier and the Customer, and Party means either one of them.</p>
<p>Products means the goods manufactured in accordance with this Agreement.</p>
<p>Services means the beverage manufacturing services, including recipe formulation, production, packaging and storage
  services to be provided by the Supplier under this Agreement </p>
<p>Specification means the Customer's specific requirements (if any) for the Services communicated to the Supplier and
  set out in Schedule or quote B.</p>
<p>Supplier’s Personnel means any person or persons that the Supplier designates to perform the Services on the
  Supplier’s behalf.</p>
<p>Supplier's Premises means premises and facilities located at the following addresses:</p>
<p>(a) 96 Chifley Drive, Preston;<br />(b) 390 Smith Street, Collingwood; and<br />(c) 2/22 Sunline Drive, Truganina.
</p>
<p>Termination Date means the earlier of:</p>
<p>(a) the date of expiry of this Agreement; and<br />(b) the date of termination of this Agreement by the Customer or
  the Supplier.</p>
<h3>1.2 Interpretation</h3>
<p>In this Agreement unless the context otherwise requires:</p>
<p>(a) words importing any gender include every gender;<br />(b) words importing the singular number include the plural
  number and vice versa;<br />(c) words importing persons include firms, companies and corporations and vice
  versa;<br />(d) references to numbered clauses, paragraphs, quotes and schedules are references to the relevant clause
  or paragraph in or schedule or quote to this Agreement;<br />(e) reference in any schedule or quote to this Agreement
  to numbered paragraphs relate to the numbered paragraphs of that schedule;<br />(f) any obligation on any Party not to
  do or omit to do anything is to include an obligation not to allow that thing to be done or omitted to be
  done;<br />(g) the headings to the clauses and schedules of this Agreement are not to affect the
  interpretation;<br />(h) any reference to an enactment includes reference to that enactment as amended or replaced
  from time to time and to any subordinate legislation or byelaw made under that enactment; and<br />(i) the word
  “including” (and related forms including “includes”) means “including without limitation”.</p>


  <h2>2. Services</h2>
<p>(a) In consideration for the Customer paying the Fees, the Supplier will provide the Services.<br />(b) The Supplier
  and the Customer will agree on the time and place for the performance of the Services, subject to the availability of
  the Supplier’s staff and agents.<br />(c) The Supplier will use reasonable endeavours to complete the Services by the
  the date agreed by the Parties.<br />(d) The Services will be performed by the employees or agents that the Supplier
  determines as most appropriate to carry out the Services.<br />(e) The Supplier may agree to allow the Customer to
  provide assistance with provision of the Services.<br />(f) The Services are to be performed as agreed by the Parties,
  subject to the provisions of this Agreement.</p>
<h2>3. Location</h2>
<p>The Supplier will provide the Services in one of the Supplier's Premises, as the Supplier considers appropriate to
  the type and nature of the requirements of the Customer, or as agreed by the Parties from time to time.</p>
<h2>4. Fees</h2>
<h3>4.1 Payment of Fees </h3>
<p>(a) In consideration of the provision of the Services, the Customer will pay the Supplier the Fees.<br />(b) For the
  sake of clarity, additional Services may be requested by the Customer during the term of this Agreement and additional
  Fees will apply to those Services.<br />(c) Where the Supplier’s charges are based on an hourly rate, any time spent
  which is less than an hour is charged on a pro-rated basis.<br />(d) The Customer acknowledges that the Fees are
  exclusive of any GST that may be charged by the Supplier to the Customer, and therefore, the Supplier will be entitled
  to add on GST.</p>
<h3>4.2 Invoicing</h3>
<p>(a) The Supplier will provide the Customer with a tax invoice in accordance with the GST Law in relation to fees
  payable under this clause 4.<br />(b) Payment will be made by the Customer to the Supplier within 7 days after
  receiving the Supplier’s invoice. When making a payment, the Customer must quote relevant reference numbers and the
  invoice number.</p>
<h3>4.3 Variation of Fees</h3>
<p>The Supplier is entitled to vary the Fees during the term of this Agreement by providing written notice to the
  Customer as soon as is reasonably practicable prior to the change being implemented.</p>
<h3>4.4 Costs and disbursements</h3>
<p>In addition to the Fees, the Supplier is permitted to charge for all costs and expenses incurred in performing the
  Services, including travelling charges, courier and freight charges, postage charges, packaging materials required for
  finished packaging to be supplied by the Customer, all external laboratory analysis by third parties; all charges
  relating to the storage of finished packaged alcohol and any other expenses incurred in providing the Services.</p>
<h3>4.5 Failure to pay</h3>
<p>If the Customer does not make a payment by the date stated in an invoice or as otherwise provided for in the
  Agreement, the Supplier is entitled to do any or all of the following:</p>
<p>(a) charge interest on the outstanding amount at the annual percentage rate equal to 4% per annum more than the rate
  from time to time fixed by the Penalty Interest Rates Act 1983 (Vic), accruing daily;<br />(b) require the Customer to
  pay, in advance, for any Services (or any part of the Services) which have not yet been performed;<br />(c) not
  perform any further Services (or any part of the Services); and<br />(d) retain possession of the Products.</p>
<h3>4.6 Set Off</h3>
<p>The Supplier may set off against any sum owing to the Customer under this Agreement in the amount then owing by the
  Customer to the Supplier.</p>
<h2>5. Customer ’s obligations</h2>
<p>(a) During the term of this Agreement, the Customer will:</p>
<p className="indent">(i) co-operate with and assist the Supplier as the Supplier reasonably
  requires;<br />(ii) provide any information, documents, ingredients, packaging, materials or anything else that the
  Supplier reasonably requires to provide the Services;<br />(iii) ensure that it does not enter the Supplier's Premises
  without prior approval of the Supplier. While at the Supplier's Premises, the Customer must work in a safe manner and
  must abide by all procedures and regulations as instructed by the Supplier, including all Occupational Health and
  Safety Procedures;<br />(iv) use the Facilities as approved and made available by the Supplier, and in accordance with
  the Supplier's instructions and requirements; and<br />(v) take full responsibility for their safety whilst at the
  Supplier's Premises and indemnify the Supplier to the fullest extent permitted by Laws from any injury, or claim
  arising from the Customer's attendance at the Supplier's Premises.</p>
<p>(b) The Supplier is not required to commence provision of the Services until the Customer has complied with its
  obligations contained in this clause 5</p>

<h2>6. No partnership or employment relationship</h2>
<p>(a) The Parties agree that they are independent contractors entering into this Agreement and no partnership, joint
  venture, employment or other relationship is contemplated or created. It is the express intention of the Parties that
  any such relationships are denied.</p>
<h2>7. Use of subcontractors</h2>
<p>(a) The Supplier is permitted to use other persons to provide some or all of the Services.<br />(b) The Supplier is
  responsible for the work of any of the Supplier’s subcontractors and any work undertaken by any of the Supplier’s
  subcontractors will be undertaken to the same standard as stated in this Agreement.</p>
<h2>8. Disclosure and ownership of intellectual property</h2>
<p>(a) The Customer grants to the Supplier an exclusive royalty free licence to use the Customer's Intellectual Property
  for the purpose of performing the Services.<br />(b) Except as permitted under this Agreement, the Supplier must not
  manufacture, or assist a third party to manufacture, the Customer's products.<br />(c) Any discoveries, improvements
  and inventions made or conceived by the Supplier or the Supplier’s Personnel (either solely or jointly with others) in
  the course of performing the Services, whether or not they contain intellectual property rights capable of protection,
  are and remain the sole and exclusive property of the Supplier, unless the Parties agree otherwise.<br />(d) The
  Customer acknowledges that the Supplier (or its associated entities or persons) owns all Intellectual Property created
  by the Supplier in connection with the Services, that now exists or that later comes into existence, unless the
  Parties agree otherwise.<br />(e) The obligations accepted by the Supplier and the Customer under this clause 8
  survive termination or expiry of this Agreement.</p>
<h2>9. Confidentiality</h2>
<p>(a) Each party must, unless it has the prior written consent of the other party keep confidential at all times the
  Confidential Information of the other party.<br />(b) Effect and maintain adequate security measures to safeguard the
  other party’s Confidential Information from unauthorised access or use.<br />(c) The Parties' obligations in relation
  to the Confidential Information will continue for as long as the Confidential Information is maintained on a
  confidential basis.<br />(d) At the other party’s request following termination or expiry of this Agreement, a party
  must promptly return to this other party or destroy all Confidential Information of the other party that is in the
  first party’s possession or control.<br />(e) The Customer agrees that the Supplier may require the Customer to sign a
  confidentiality agreement in a form that the Supplier approves, as a condition of this Agreement.<br />(f) The
  Customer agrees to indemnify the Supplier fully against all liabilities, costs and expenses which the Supplier may
  incur as a result of any breach of this clause 9 by the Customer.<br />(g) The obligations accepted by the Supplier
  and the Customer under this clause 9 survive termination or expiry of this Agreement.</p>
<h2>10. Warranties, liability and indemnities</h2>
<h3>10.1 Warranties</h3>
<p>(a) Each Party warrants that it is entitled to enter into this Agreement and holds all licences, authorisations,
  consents and approvals of any kind required by applicable Laws in order to perform its obligations under this
  Agreement.<br />(b) Each Party warrants that it is solvent, able to pay its debts when they are due, and is not under
  the control of an administrator, receiver or liquidator.<br />(c) The Supplier warrants that it will use reasonable
  care and skill in performing the Services.<br />(d) The Supplier warrants that it has suitably qualified employees to
  carry out the Services in accordance with this Agreement.<br />(e) The Supplier warrants that all Products will be
  free from material defects in workmanship and will comply with the Specifications. If the Customer considers that any
  Products have a material defect, the Customer must provide the Supplier with written notice of the defect within 1
  month of the date the Supplier completed performing the Services, and deliver the Products to the Supplier's Premises.
  If the Supplier determines the Products are materially defective, the Supplier will re-perform the relevant part of
  the Services, subject to clause 10.5.<br />(f) The Customer warrants that it is responsible for any defects in the
  Products caused as a result of its recipe, or as a result of assistance which it provided in performing the Services.
</p>
<h3>10.2 Insurances</h3>
<p>(a) The Supplier must take out all insurances required by applicable Laws including worker’s compensation insurance
  for the Supplier’s Personnel and public liability insurance.<br />(b) During the performance of the Services, the
  Customer acknowledges that its property, including the Products, its ingredients, equipment, will remain at the
  Customer's risk and will be the Customer's responsibility to take out insurances to cover such risks. Prior to
  commencement of the Services, if requested the Customer will immediately provide the Supplier with certificates of
  insurances required under this Agreement.</p>
<h3>10.3 Compliance with all laws</h3>
<p>During the performance of the Services, each Party must comply with all Laws at that party's own cost and expense.
</p>
<h3>10.4 No warranties in relation to completion</h3>
<p>The Supplier provides no warranty that any result or objective can or will be achieved or attained at all or by a
  given completion date or any other date, whether stated in this Agreement, or elsewhere.</p>
<h3>10.5 Limitation on liability</h3>
<p>(a) Except in the case of death or personal injury caused by the Supplier’s negligence, the liability of the Supplier
  under or in connection with this Agreement whether arising in contract, tort, negligence, breach of statutory duty or
  otherwise must not exceed the Fees paid by the Customer to the Supplier under this Agreement.<br />(b) If the
  Products, or part of the Products are defective, the Customer acknowledges its sole remedy, is that the Supplier will
  replace the Products or part of the Products and will be responsible for any delivery costs associated with
  transporting the Products.<br />(c) Neither Party is liable to the other Party in contract, tort, negligence, breach
  of statutory duty or otherwise for any loss, damage, costs or expenses of any nature whatsoever incurred or suffered
  by that other Party of an indirect or consequential nature including any economic loss or other loss of turnover,
  profits, business or goodwill.</p>
<h3>10.6 Indemnity</h3>
<p>The Customer must indemnify and hold the Supplier harmless from and against all Claims and Losses arising from this
  Agreement including:</p>
<p>(a) Losses, injury or liability of any kind caused to the Supplier;<br />(b) infringement of third party intellectual
  property, or third party Losses by reason of or arising out of any information supplied to the Customer by the
  Supplier; and<br />(c) Losses or damage caused to the Customer's products while in the Supplier's possession, custody
  or located at the Supplier's Premises.</p>
<h3>10.7 No reliance</h3>
<p>Each of the Parties acknowledges that, in entering into this Agreement, it does not do so in reliance on any
  representation, warranty or other provision except as expressly provided in this Agreement. Any conditions, warranties
  or other terms implied by statute or common law are excluded from this Agreement to the fullest extent permitted by
  applicable Laws.</p>
<h3>10.8 Survival of obligations</h3>
<p>The obligations accepted by the Supplier and the Customer under this clause 10 survive termination or expiry of this
  Agreement.</p>
<h2>11. Termination</h2>
<p>(a) The Supplier may terminate this Agreement by notice in writing to the Customer if the Customer:</p>
<p className="indent">(i) fails to observe any term of this Agreement; and<br />(ii) fails to rectify this
  breach, to the satisfaction of the Supplier, following the expiration of 7 days’ notice of the breach being given in
  writing to the Customer.</p>
<p>(b) Either Party may terminate this Agreement upon the happening of any of the following events:</p>
<p className="indent">(i) if the Customer enters into a deed of arrangement or an order is made for it to be
  wound up;<br />(ii) if an administrator, receiver or receiver/manager or a liquidator is appointed to the Customer
  pursuant to the Corporations Act 2001 (Cth); or<br />(iii) if the Customer would be presumed to be insolvent by a
  court in any of the circumstances referred to in the Corporations Act 2001 (Cth).</p>
<p>(c) Upon termination of this Agreement any fees, expenses or reimbursements payable by the Customer to the Supplier
  in respect of any period prior to the Termination Date must be paid by the Customer within 7 days after the
  Termination Date.</p>
<h2>12. General</h2>
<h3>12.1 Force Majeure</h3>
<p>(a) Neither Party has any liability under or may be deemed to be in breach of this Agreement for any delays or
  failures in performance of this Agreement which result from circumstances beyond the reasonable control of that
  Party.<br />(b) The Party affected by these circumstances must promptly notify the other Party in writing when such
  circumstances cause a delay or failure in performance and when they cease to do so.<br />(c) If such circumstances
  continue for a continuous period of more than 6 months, either Party may terminate this Agreement by written notice to
  the other Party.</p>
<h3>12.2 Amendment</h3>
<p>This Agreement may only be amended in writing signed by duly authorised representatives of the Parties.</p>
<h3>12.3 Entire Agreement</h3>
<p>(a) This Agreement contains the whole Agreement between the Parties in respect of the subject matter of the
  Agreement.<br />(b) The Parties confirm that they have not entered into this Agreement on the basis of any
  representation that is not expressly incorporated into this Agreement.</p>
<h3>12.4 Waiver</h3>
<p>(a) No failure or delay by the Supplier in exercising any right, power or privilege under this Agreement will impair
  the same or operate as a waiver of the same nor may any single or partial exercise of any right, power or privilege
  preclude any further exercise of the same or the exercise of any other right, power or privilege.<br />(b) The rights
  and remedies provided in this Agreement are cumulative and not exclusive of any rights and remedies provided by
  applicable Laws.</p>
<h3>12.5 Severance</h3>
<p>If any provision of this Agreement is prohibited by law or judged by a court to be unlawful, void or unenforceable,
  the provision will, to the extent required, be severed from this Agreement and rendered ineffective as far as possible
  without modifying the remaining provisions of this Agreement, and will not in any way affect any other circumstances
  of or the validity or enforcement of this Agreement.</p>
<h3>12.6 Notices</h3>
<p>A notice or other communication connected with this Agreement has no legal effect unless it is in writing. The notice
  may be sent by pre-paid post to the address of the addressee as set out in this Agreement, or sent by email or fax to
  the email or fax number of the addressee.</p>
<h3>12.7 Law and jurisdiction</h3>
<p>This Agreement takes effect, is governed by, and will be construed in accordance with the laws from time to time in
  force in Victoria, Australia. The Parties submit to the non-exclusive jurisdiction of the courts of Victoria.</p>

                     <Link to="/" className="dark-link"><span className="back-arrow">→</span>Return Home</Link>
                    </div>
                </div>
            </div>
            <section className="footer background--dark" name="contact" id="contact">
                <Footer></Footer>
                <div className="container footer--bottom">
                    <div className="wrapper--outer">
                    <img className="logo-small" src={logoGold} alt="gypsy hub gold logo" width="150" height="150" />
                    </div>
                </div>
            </section>
        </div>
        );
    }
